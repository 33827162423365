<template>
    <div class="consumer-view">
        <section class="visual">
            <div class="row">
                <div class="col-12 m-col-6">
                    <h1>Maak van je<br />oude verf,<br />nieuwe verf</h1>
                </div>
                <div class="col-12 m-col-6 text-right">
                    <img src="/static/img/pppw.png" class="pppwlogo" alt="PPPW Logo">
                </div>
            </div>
        </section>
        <section class="white">
            <div class="row">
                <div class="col-12 m-col-6">
                    <img src="/static/img/ladder-pot.jpg" class="slide slide1" alt="">
                </div>
                <div class="col-12 l-col-6">
                    <h2>Wist je dat verfresten <br />gerecycled kunnen worden?</h2>
                    <p>Wellicht herken je dit wel: je hebt een muur geschilderd en houdt redelijk wat verf over. Wie weet kun je het nog een keer gebruiken dus hup in de kast ermee. Uit onderzoek blijkt dat we in Nederland gemiddeld 3,5 blikken oude verf in huis hebben staan. En van een deel van die verfrestanten maken we bij Sikkens graag nieuwe verf. Onze Sikkens Alpha Recycle Mat is een witte muurverf met een matte uitstraling die voor 35% uit gerecyclede verf bestaat.</p>
                </div>
            </div>
        </section>
        <section class="paint">
            <div class="row">
                <div class="col-12 l-col-6 l-col-offset-3">
                    <div class="block">
                        <h2>KIES MILIEU<span style="font-family: sans-serif;">&shy;</span>BEWUST</h2>
                        <p>Verf belandt soms in het riool of wordt vaak verbrand en dit is schadelijk voor mens en milieu. Lever daarom altijd jouw oude verf in bij de milieustraat. Zo zorg je niet alleen voor minder milieubelastend verfafval, maar maakt je oude verf ook kans op een tweede leven. Sommige verfsoorten kunnen namelijk gerecycled worden.</p>
                        <p>Help je mee de wereld meter voor meter beter te maken? Lever dan je verfblikken in bij een verzamelpunt van Klein Chemisch Afval (KCA), bijvoorbeeld bij de milieustraat bij jou in de buurt. Kijk op de website van je gemeente voor de dichtstbijzijnde inleverlocatie.</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="duurzaam">
            <div class="row">
                <div class="col-12 l-col-5">
                    <h2>DRAAG BIJ AAN EEN DUURZAMERE WERELD</h2>
                    <p>Sikkens wil, nu en in de toekomst, bouwen aan een duurzamere wereld. Daarom zijn we continu in ontwikkeling op gebied van innovatieve oplossingen voor zowel de gebruiker als voor onze verven. Deze oproep is hier een mooi voorbeeld van. Niet alleen krijgt verf zo een tweede leven, ook zorgt het ervoor dat er minder verfafval is wat weer beter is voor het milieu.</p>
                    <p>Uit onderzoek is gebleken dat &mdash; nu men weet dat een deel van de verf gerecycled kan worden &mdash; ruim driekwart van de huishoudens bereid is om hun overgebleven verf voortaan naar de milieustraat te brengen. Dit vinden wij geweldig om te horen, zo kunnen we met elkaar oude verf écht een tweede leven geven.</p>
                    <p>Lees hier meer over <a href="https://www.akzonobel.com/en/about-us/sustainability-#tabId=item_1615296851183" target="_blank">People. Planet. Paint.</a></p>
                </div>
                <div class="col-12 l-col-6 l-col-offset-1">
                    <img src="/static/img/duurzaam2.jpg" class="slide slide2" alt="Duurzaam bijdragen">
                </div>
            </div>
        </section>
        <section class="wistjedat">
            <div class="row">
                <div class="col-12 l-col-3">
                    <h2>MOOI<br />INITIATIEF</h2>
                </div>
                <div class="col-12 m-col-8 l-col-7">
                    <h3>Wist je dat we ook een deel van deze gerecyclede Sikkens verf schenken aan de Ronald McDonald Huizen? Dus jouw verfdonatie steunt ook een goed doel. Vakantiehuis het Boshuus in Arnhem is het eerste project dat deze gerecyclede verf al krijgt. Meer weten over het Ronald McDonald Kinderfonds. Ga naar <a href="https://www.kinderfonds.nl" target="_blank">www.kinderfonds.nl</a></h3>
                </div>
                <div class="col-12 m-col-4 l-col-2">
                    <img src="/static/img/mcdonalds.png" />
                </div>
            </div>
        </section>
        <section class="white three">
            <div class="row">
                <div class="col-12">
                    <h2>Duurzame tips voor <br /> de doe-het-zelver</h2>
                </div>
                <div class="col-12 m-col-4">
                    <div class="tip">
                        <h3>Tip 1</h3>
                        <p>Ga je meerdere lagen verf aanbrengen? Bewaar dan je roller of kwasten in de gesloten emmer. Dat scheelt schoonmaken, en dus vervuild water, en tijd.</p>
                    </div>
                </div>
                <div class="col-12 m-col-4">
                    <div class="tip">
                        <h3>Tip 2</h3>
                        <p>Klaar met je schilderklus? Vang het spoelwater van je rollers en kwasten op en breng het samen met je verfrestanten naar de milieustraat. Zo komt er geen milieubelastende verf in het riool terecht.</p>
                    </div>
                </div>
                <div class="col-12 m-col-4">
                    <div class="tip">
                        <h3>Tip 3</h3>
                        <p>Heb je de verf later nog een keer nodig, sluit dan na gebruik de bus goed en houd hem even op de kop. Er loopt zo wat verf in de rand tussen de deksel en de bus, waardoor de verf luchtdicht is afgesloten en niet voortijdig gaat indrogen.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 m-col-4">
                    <div class="tip">
                        <h3>Tip 4</h3>
                        <p>Gebruik verfreiniger voor het ontvetten. Dit ontvet beter dan bijvoorbeeld ammonia en heeft een veel aangenamere geur. </p>
                    </div>
                </div>
                <div class="col-12 m-col-4">
                    <div class="tip">
                        <h3>Tip 5</h3>
                        <p>Kies voor muurverf met een hoge dekking, zoals Sikkens Alphatex SF, zo schilder je meer meters met minder verf.</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="quotes">
            <v-slick-carousel v-bind="settings" >
                <h2>“Meer dan een derde van de (huur)huiseigenaren weet niet dat je verf kunt recyclen.”</h2>
                <h2>“58% van de (huur)huiseigenaren brengt nooit verf naar een chemisch afvalpunt.”</h2>
                <h2>“Ruim driekwart van de (huur)huiseigenaren is bereid verf naar de milieustraat te brengen als ze weten dat de verf gerecycled wordt.”</h2>
                <h2>“Bijna de helft van (huur)huiseigenaren weet niet wat de gevolgen zijn als ze verf door de gootsteen spoelen.”</h2>
            </v-slick-carousel>
        </section>
    </div>
</template>

<style lang="scss">
    @import "base/settings";

    .consumer-view{
        padding: 100px 10px 10px 10px;
        @media (min-width: $medium ) {
            padding: 100px 30px 30px 30px;
        }
        .visual {
            margin: -100px -10px 0 -10px;
            @media (min-width: $medium ) {
                margin: -100px -30px 0 -30px;
            }
        }
        h1 {
            text-transform: uppercase;
            margin-bottom: 0;
            line-height: 1.15;
        }

        .text-right {
            text-align: right;
        }
        img.pppwlogo {
            max-width: 25%;
            @media (min-width: $medium)
            {
               max-width: 100%;
            }
        }

        .white {
            h2 {
                text-transform: uppercase;
                margin: 0.5em 0;
            }
            p {
                margin-top: 0;
            }

            @media (min-width: $small) {
                img {
                    padding-right: 50px;
                }
            }
        }

        .visual {
            padding: 100px 10px 175px 10px;
            @media (min-width: $small) {
                padding: 100px 30px 175px 30px;
            }
            background: white url(/static/img/header-consument.jpg) no-repeat top center;
            background-size: cover;
            min-height: 60vh;
            &:after {
                content: '';
                background: transparent url(/static/img/dots.svg) no-repeat top center;
                background-size: contain;
                position: absolute;
                bottom: 0;
                right: 0;
                width: 50vw;
                transform: translateX( 25vw ) translateY( 40vh );
                height: 100%;
            }
        }
        section {
            padding: 15px;
            @media (min-width: $small) {
                padding: 80px;
            }
            @media (min-width: $medium) {
                padding: 160px;
            }
        }

        .paint {
            background: transparent url(/static/img/paint.jpg) no-repeat center center;
            background-size: cover;
            margin: 0 -10px 0 -10px;
            @media (min-width: $medium ) {
                margin: 0 -30px 0 -30px;
            }

            @media (max-width: $small) {
                padding: 35px 25px;
            }

            .block {
                background: rgba( 255, 255, 255, 1 );
                padding: 3rem 5rem;
                h3 {
                    margin-top: 0;
                }
            }
        }

        .wistjedat {
            background-color: $green;
            padding: 50px;
            color: $blue;
            margin: 0 -10px 0 -10px;
            @media (min-width: $medium ) {
                margin: 0 -30px 0 -30px;
            }

            h3 {
                border-left: 3px solid $blue;
                padding-left: 75px;
            }
            a {
                color: $blue;
            }
        }

        .white.three {
            position: relative;
            @media (min-width: $medium) {
                padding-top: 80px;
            }
            &:before {
                content: '';
                background: transparent url(/static/img/dots.svg) no-repeat top center;
                background-size: contain;
                position: absolute;
                top: -40%;
                left: -30%;
                width: 50vw;
                transform: rotate(180deg);
                height: 100%;
                z-index: -1;
            }

            h2 {
                margin: 0;
                margin-bottom: 1em;
            }

            text-align: center;
            p {
                font-family: 'Lato', sans-serif;
            }
            .row>div {
                padding: 0 20px;
            }

            @media (min-width: $small) {
                font-size: 20px;
            }

            @media (min-width: $medium ) {
                font-size: 25px;
            }

            .tip {
                background: $lightgray;
                border-radius: 5px;
                h3 {
                    text-transform: uppercase;
                    background: $gray;
                    color: white;
                    padding: 0.5em;
                    border-radius: 5px;
                    font-size: 0.75em;
                    margin: 0;
                }
                p {
                    padding: 1em;
                    @media (min-width: $medium ) {
                        min-height: 13em;
                    }
                }
            }
        }
        .quotes {
            background: $blue;
            margin: 0 -10px 0 -10px;
            @media (min-width: $medium ) {
                margin: 0 -30px 0 -30px;
            }

            h2 {
                color: white;
                text-align: center;
            }
        }

        .duurzaam {
            a {
                color: $blue;
            }
        }

        @media (min-width: $small) {
            img.slide {
                opacity: 0;
                transform: translateX(-100px);
            }
        }

        .slide1, .slide2 {
            opacity: 0;
        }
        .slide1.animate {
            animation: slideImageLeftToRight 1s ease-in-out forwards;
        }
        .slide2.animate {
            animation: slideImageRightToLeft 1s ease-in-out forwards;
        }
    }
</style>

<script>

    import VueSlickCarousel from 'vue-slick-carousel';

    export default {
        name: 'Consumer',
        components: {
            'v-slick-carousel': VueSlickCarousel
        },

        data()
        {
            return {
                settings: {
                    infinite: true,
                    slidesToShow: 1,
                    dots: true,
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 4000,
                    draggable: true,
                    swipe: true,
                    touchMove: true
                }
            };
        },

        mounted()
        {
            this.observeDocument();
        },

        methods: {
            observeDocument()
            {
                const options = {
                    root: null,
                    rootMargin: '0px',
                    threshold: 0.2
                };

                this.observer = new IntersectionObserver( entries =>
                {
                    entries.forEach( entry =>
                    {
                        const image = entry.target;

                        if( entry.isIntersecting )
                        {
                            image.classList.add( 'animate' );
                        }
                    });
                }, options );
                const slides = document.querySelectorAll( '.slide' );
                slides.forEach( ( el ) =>
                {
                    this.observer.observe( el );
                });
            }
        },

        beforeDestroy()
        {
            this.observer.disconnect();
        }
    };
</script>
